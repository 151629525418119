<template>
    <div>
        <!-- :limit="limit" -->
        <!-- :on-exceed="handleExceed" 禁用个数限制 -->
        <!-- :before-upload="before_Upload" 禁用大小限制 -->
        <el-upload class="upload-demo" :action="$rootUrl + upUrl + '?approvalSignId=' + id" ref="upload" :headers="{
            Authorization: 'Bearer ' + TokenCache.getToken(),
        }" :on-preview="handlePreview" :on-remove="handleRemove" :before-remove="beforeRemove" :list-type="listType"
            :disabled="disabled" :multiple="true" :drag="drag" :on-success="uploadSuccess" :file-list="fileList"
            :on-change="uploadChange" :auto-upload="false">

            <div v-if="drag">
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            </div>
            <div v-if="!drag"><el-button size="small" type="primary">{{ disabled ? '已禁用上传' : '点击上传' }}</el-button></div>
            <!-- <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div> -->
        </el-upload>
    </div>
</template>
<script>
import TokenCache from "@/utils/cache/TokenCache";
import { status } from "nprogress";
export default {
    data() {
        return {
            TokenCache,
            fileList: [],
            List: [],

            codeList: []
        };
    },
    props: {
        id: {
            type: String,
            default: ''
        },
        upUrl: {
            type: String,
            default: '/Shop_Manage/Shop_ApprovalInfo_FAL/OssUploadFileByForm'
        },
        delUrl: {
            type: String,
            default: '/Shop_Manage/Shop_ApprovalInfo_FAL/DeleteData'
        },
        value: {
            type: Array,
            default: () => []
        },
        //限制上传文件数量
        limit: {
            type: Number,
            default: 6
        },
        // KB形式大小
        size: {
            type: Number,
            default: 1024
        },
        // 是否禁用
        disabled: {
            type: Boolean,
            default: false
        },
        // 文件列表的样式类型
        // text 普通/picture图片列表/picture-card照片墙
        listType: {
            type: String,
            default: 'text'
        },
        // 是否拖拽上传
        drag: {
            type: Boolean,
            default: false
        }
    },
    components: {},
    methods: {
        async upload() {
            if (this.fileList.length > 0) {
                let flag = this.fileList.every(item => {
                    return item.status == 'success'
                })
                if (flag) {
                    return false
                }
                await this.$refs.upload.submit();
                return true
            } else {
                return false
            }

        },
        uploadChange(file, fileList) {
            this.fileList = [...fileList]
            console.log(this.fileList)
            // this.$emit('input', fileList)
            // console.log(file, fileList, '/////////////')
        },
        uploadSuccess(response, file, fileList) {
            console.log(response, file, fileList, '上传')
            let falg = fileList.every(item => {
                return item.status == 'success'
            })
            if (falg) {
                let arr = []
                let errArr = []
                fileList.forEach(item => {
                    if (item.FileWebURL || (item.response && item.response.Code == '200')) {
                        arr.push(item)
                    } else {
                        item.status = 'ready'
                        item.percentage = 0
                        errArr.push(item)
                    }
                })
                console.log(arr, errArr, 'arr')
                this.fileList = fileList
                if (errArr.length > 0) {
                    this.$emit('error', errArr)
                    this.List = [...fileList]
                } else {
                    this.$emit('success', this.fileList)
                }


            }

            // if (response.Code == '200') {
            //     console.log(file, '22222')
            //     this.codeList.push(file)
            // }
            // if (this.codeList.length == fileList.length) {

            // } else {
            //     this.$emit('error', this.fileList)
            // }

        },
        before_Upload(file) {
            if ((file.size / 1024) > this.size) {
                this.$message.error('文件大小不能超过' + this.size + 'kb')
                return false;
            }
        },
        // 文件列表移除文件时的钩子
        handleRemove(file, fileList) {
            this.fileList = fileList
        },
        // 点击文件列表中已上传的文件时的钩子
        handlePreview(file) {
            console.log(file)
            window.open(file.url || file.response.Url);
        },
        // 选择超出限制数量时的钩子
        handleExceed(files, fileList) {
            this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
        },
        // 点击X删除时弹窗确认
        beforeRemove(file, fileList) {
            console.log(file)
            // this.$emit('ondelete', file, fileList)
            if (file.Id) {
                return this.$confirm(`确定移除 ${file.name}？`).then(res => {
                    this.$http.post(this.delUrl, [file.Id]).then(res => {
                        if (res.Success) {
                            this.$message.success('删除成功')
                        }
                    })
                })
            }

        }
    },
    mounted() { },
    created() { },
    computed: {},
    watch: {
        value: {
            handler(newVal) {
                this.fileList = newVal
            },
            deep: true,
            immediate: true
        },
        fileList: {
            handler(newVal) {
                console.log(newVal, 'value改变了')
                this.$emit('input', newVal)
            },
            deep: true,
            immediate: true
        }
    },
};
</script>
<style lang='scss' scoped></style>